import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { useContentfulSection, useMeta, caseStudyFilters } from "utils"
import { Layout, Seo, TextGroup } from "components/common"
import { FeaturedPost, Posts } from "src/components/post"
import { PrimaryNav, MobileNav } from "src/components/navigation"

const CaseStudyPage = () => {
  const { featured, caseStudies, page } = useStaticQuery(QUERY)
  const { caseStudy_filter, case_study_hero } = useContentfulSection(
    page.sections
  )
  const meta = useMeta(page)

  return (
    <Layout>
      <Seo {...meta} />
      <MobileNav />
      <PrimaryNav />
      <StyledCaseStudy>
        <TextGroup
          className="cs_hero"
          title={case_study_hero.title}
          subtitle={case_study_hero.subtitle}
          titleAs="h1"
        />
        <FeaturedPost
          {...featured}
          hat="Featured Case Study"
          slugBase="/case-study"
        />
      </StyledCaseStudy>
      <Posts
        posts={caseStudies}
        title={caseStudy_filter.title}
        filters={caseStudyFilters}
        slugBase="/case-study"
      />
    </Layout>
  )
}

const StyledCaseStudy = styled.div`
  .cs_hero {
    --tg-text-align: center;
    margin-top: var(--sp-mobile-nav);
    /* margin-top: var(--sp-nav); */
  }
`

const QUERY = graphql`
  {
    page: contentfulPage(pageId: { eq: "case_study" }) {
      ...Page
    }
    featured: contentfulCaseStudy(featuredPost: { eq: true }) {
      ...CaseStudy
    }
    caseStudies: allContentfulCaseStudy(
      filter: { slug: { ne: "cs-placeholder-dont-delete" } }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          ...CaseStudy
        }
      }
    }
  }
`

export default CaseStudyPage
